import coverView from './../../mixins/cover-view'

const xunjianPassed = {
  mixins: [coverView],
  props: {
    // 需要显示在地图中的自定义标注物
    dbDevicesData: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      endMarker: undefined,
      startMarker: undefined,
      xunjianPeopleMarker: undefined,
      xunjianPeopleIconPath: 'http://srcp.fangshuoit.com/icon_xunjian_user.png',
      xunjianStartIconPath: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
      xunjianEndIconPath: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
      xunjianPolyline: undefined,
      xunjianPassedPolyline: undefined,
      timeoutIndex: undefined,
      devicesOverlayGroup: undefined,
      track: [],
      online: false,
      // 多少秒完成整个动画
      animationTime: 2,
    }
  },
  methods: {
    /**
     * 清楚页面上的所有标记
     */
    clearTrack() {
      if (this.timeoutIndex) {
        clearTimeout(this.timeoutIndex)
        this.timeoutIndex = undefined
      }

      if (this.endMarker) {
        this.endMarker.setMap(null)
        this.endMarker = undefined
      }

      if (this.startMarker) {
        this.startMarker.setMap(null)
        this.startMarker = undefined
      }

      if (this.xunjianPolyline) {
        this.xunjianPolyline.setMap(null)
        this.xunjianPolyline = undefined
      }

      if (this.xunjianPeopleMarker) {
        this.xunjianPeopleMarker.setMap(null)
        this.xunjianPeopleMarker = undefined
      }

      if (this.xunjianPassedPolyline) {
        this.xunjianPassedPolyline.setMap(null)
        this.xunjianPassedPolyline = undefined
      }
    },

    /**
     * 创建巡检轨迹
     */
    buildTrack() {
      this.clearTrack()
      // 设置基础设备覆盖物
      this.getDevicesOverlayGroup()
      // 设置轨迹
      if (this.track && this.track.length > 0) {
        this.markerStartAndEnd()
        this.buildXunjianPeopleMarker()
        this.markerXunjianPolyline()
        // 只有起点 适应到起点位置
        if (this.track.length === 1) {
          this.map.setFitView(this.xunjianPeopleMarker)
        } else if (this.track.length > 1) {
          this.buildXunjianPassedPolyline()
          this.xunjianPassedStart()
        }
      }
    },

    /**
     * 标记开始和终点
     */
    markerStartAndEnd() {
      let endIcon = new AMap.Icon({
        size: new AMap.Size(25, 34),
        image: this.xunjianEndIconPath,
        imageSize: new AMap.Size(135, 40),
        imageOffset: new AMap.Pixel(-95, -3),
      })
      let startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(25, 34),
        // 图标的取图地址
        image: this.xunjianStartIconPath,
        // 图标所用图片大小
        imageSize: new AMap.Size(135, 40),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(-9, -3),
      })

      // 大于1个点
      if (this.track.length > 1) {
        // 并且已完成
        if (!this.online) {
          this.endMarker = new AMap.Marker({
            map: this.map,
            icon: endIcon,
            position: this.track[this.track.length - 1],
            offset: new AMap.Pixel(-13, -33),
          })
        }
        // 大于两个点
        this.startMarker = new AMap.Marker({
          map: this.map,
          icon: startIcon,
          position: this.track[0],
          offset: new AMap.Pixel(-11, -30),
        })
      }
    },

    /**
     * 标记巡检轨迹
     */
    markerXunjianPolyline() {
      if (this.track.length > 1) {
        this.xunjianPolyline = new AMap.Polyline({
          map: this.map,
          path: this.track,
          showDir: true,
          strokeColor: '#2d8cf0', //线颜色--蓝色
          //strokeOpacity: this.online ? 0 : 1,     //线透明度
          strokeOpacity: 1, //线透明度
          strokeWeight: 4, //线宽
          // strokeStyle: "solid"  //线样式
        })
        this.map.setFitView(this.xunjianPolyline)
      }
    },

    /**
     * 标记巡检人图标
     */
    buildXunjianPeopleMarker() {
      this.xunjianPeopleMarker = new AMap.Marker({
        map: this.map,
        position: this.track[0],
        icon: new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(33, 33),
          // 图标的取图地址
          image: this.xunjianPeopleIconPath,
          // 图标所用图片大小
          imageSize: new AMap.Size(33, 33),
        }),
        offset: new AMap.Pixel(-18, -33),
      })

      this.xunjianPeopleMarker.on('moving', (e) => {
        if (this.xunjianPassedPolyline) {
          this.xunjianPassedPolyline.setPath(e.passedPath)
        }
      })

      // if (!this.online) {
      //   this.timeoutIndex = setTimeout(() => {
      //     this.xunjianPeopleMarker.hide()
      //   }, this.animationTime * 1000 + 500)
      // }
    },

    /**
     * 标记巡检轨迹回放字段
     */
    buildXunjianPassedPolyline() {
      this.xunjianPassedPolyline = new AMap.Polyline({
        map: this.map,
        showDir: true,
        strokeColor: '#87d068', //线颜色
        // strokeOpacity: this.online ? 1 : 0,     //线透明度
        strokeOpacity: 1, //线透明度
        strokeWeight: 4, //线宽
      })
    },

    /**
     * 设置轨迹回放动画以及速率
     */
    xunjianPassedStart() {
      // 需要根据距离计算速度
      this.xunjianPeopleMarker.moveAlong(this.track, this.mileage / (this.animationTime / 3600))
    },

    /**
     * 获取设备基础信息group组
     * 只会创建一次，地图覆盖物一般情况不会变更
     */
    getDevicesOverlayGroup() {
      if (!this.devicesOverlayGroup) {
        const overlays = []
        // 设置设备覆盖物
        if (this.dbDevicesData) {
          this.dbDevicesData.forEach((item) => {
            let cover = this.buildCover(item)
            let overlay = cover.cover[0]
            if (cover.type === 'text') {
              // 设置 labelLayer 到当前地图中
              this.getLabelLayer(this.map).add(overlay)
            } else {
              overlays.push(overlay)
            }
          })
        }
        this.devicesOverlayGroup = new AMap.OverlayGroup(overlays)
        this.devicesOverlayGroup.setMap(this.map)
      }
      return this.devicesOverlayGroup
    },
  },
}

export default xunjianPassed
